import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import firebaseAuthService from "../services/firebase/firebaseAuthService";
import IOService from "service/IOService";
import { getURL, post } from "networking/Server";
import { Store } from "networking/stores/Store";

class Auth extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.fetchUser();

    this.props.setUserData(localStorageService.getItem("auth_user"));
    this.checkJwtAuth();
    // this.checkFirebaseAuth();
  }

  fetchUser = () => {
    post("/api/auth/me", {}).then((res) => {

      if (!res.result && localStorage.getItem("token")) {
        localStorage.removeItem("token")
        window.location.href = "/session/signin"
        return;
      }

      Store.setUser(res.user);
      if (res?.user?.user_type_id === 4)
        Store.setPanels();
      console.log(res);
    });
  };

  checkJwtAuth = () => {
    let old_io = Store.io;

    setTimeout(() => {
      Store.setIO(new IOService(getURL()));
    }, 3000);

    jwtAuthService.loginWithToken().then((user) => {
      this.props.setUserData(user);
    });
  };

  checkFirebaseAuth = () => {
    firebaseAuthService.checkAuthStatus((user) => {
      if (user) {
        console.log(user.uid);
        console.log(user.email);
        console.log(user.emailVerified);
      } else {
        console.log("not logged in");
      }
    });
  };

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(mapStateToProps, { setUserData })(Auth);
