import { lazy, useState, useEffect } from "react";
import { get } from "networking/Server";


const WorkingArea = lazy(() => import("./WorkingArea/WorkingArea"));
const AddFolder = lazy(() => import("./Folder/AddFolder"));
const Panel = lazy(() => import("./Panel/Panel"));
const MainPanel = lazy(() => import("./Panel/MainPanel"));
const Folder = lazy(() => import("./Folder/Folder"));
const UserProfile = lazy(() => import("./Settings/UserProfile"));
const UpdateUserProfile = lazy(() => import("./Settings/UpdateUserProfile"));
const Teams = lazy(() => import("./Teams/Teams"));
const InviteUser = lazy(() => import("./Teams/InviteUser"));
const Archive = lazy(() => import("./Archive/archive"));
const Processor = lazy(() => import("./Processor/Processor"));
const UnreadMail = lazy(() => import("./Inbox/UnreadMail"));
const ReadMail = lazy(() => import("./Inbox/ReadMail"));
const AllMail = lazy(() => import("./Inbox/AllMail"));
const FavoriteMail = lazy(() => import("./Inbox/FavoriteMail"));
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));

const AppProgressbar = lazy(() => import("./progressbar/AppProgressbar"));

const AppTable = lazy(() => import("./tables/AppTable"));

const AppTooltip = lazy(() => import("./tooltip/AppTooltip"));

const AppModal = lazy(() => import("./modals/AppModal"));

const AppSlider = lazy(() => import("./slider/AppSlider"));

const AppRating = lazy(() => import("./ratings/AppRating"));

const uiKitsRoutes = [
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: '/working_area/:working_area_id',
    component: WorkingArea
  },
  {
    path: "/add_folder",
    component: AddFolder
  },
  {
    path: "/panel/:panel_id",
    component: Panel
  },
  {
    path: "/panel/main_panel/:panel_id",
    component: MainPanel
  },
  {
    path: "/folder/:folder_id",
    component: Folder
  },
  {
    path: "/settings/user_profile/",
    component: UserProfile
  },
  {
    path: "/settings/update_user_profile/",
    component: UpdateUserProfile
  },
  {
    path: "/teams/teams",
    component: Teams
  },
  {
    path: "/invite_user",
    component: InviteUser
  },
  {
    path: "/archive/",
    component: Archive
  },
  {
    path: "/processor/",
    component: Processor
  },

  {
    path: "/inbox/unread-mail",
    component: UnreadMail
  },
  {
    path: "/inbox/read-mail",
    component: ReadMail
  },
  {
    path: "/inbox/all-mail",
    component: AllMail
  },
  {
    path: "/inbox/favorite-mail",
    component: FavoriteMail
  },

  {
    path: "/uikits/tables",
    component: AppTable
  },
  {
    path: "/uikits/tooltip",
    component: AppTooltip
  },
  {
    path: "/uikits/modals",
    component: AppModal
  },
  {
    path: "/uikits/sliders",
    component: AppSlider
  },
  {
    path: "/uikits/rating",
    component: AppRating
  }
];

export default uiKitsRoutes;
