import { observable, action, makeAutoObservable, configure } from "mobx";
import { post, get } from "../Server";

configure({ enforceActions: "never" })

class InstantStoreC {
  loading = false
  user = {};
  modal = {};
  io = null;
  data = [];
  groups = [];
  filteredGroups = [];
  panel = ""
  working_areas = []
  selected_working_areas = { working_area_id: null, panels: null }
  sidebar_search_button_click = false
  dark_mode = false
  panels = []

  constructor() {
    makeAutoObservable(this)
  }

  setUser(data) {
    this.user = data;
  }
  setModal(data) {
    this.modal = data;
  }
  changeModal(data) {
    this.modal = { ...this.modal, ...data };
  }

  setData(data) {
    this.data = data;
  }

  setGroupsFirst(panel_id) {
    this.loading = true
    post(`/api/group/`, {
      panel_id,
    }).then((res) => {
      if (!res.error) {
        this.groups = [...res]
        this.filteredGroups = [...res]
      }
      this.loading = false
    });
  }

  setGroups(data) {
    this.groups = [...data]
    this.filteredGroups = [...data]
  }

  setFilteredGroups(value) {
    const a = this.groups.filter(data => {
      return data.title.toLowerCase().includes(value.toLowerCase())
    })

    if (value === "")
      this.filteredGroups = this.groups
    else
      this.filteredGroups = a
  }

  setPanel(panel_id) {
    post(`/api/panel/`, {
      panel_id,
    }).then((res) => {
      this.panel = res
    });
  }

  setPanels() {
    post('/api/panelUser/fetchByUserId')
      .then((res) => {
        if (!res.error) {
          this.panels = res
        }
      })
  }

  setDarkMode(data) {
    this.dark_mode = data
  }

  setWorkingAreas() {
    get("api/working_area_user/").then((res) => {

      if (!Array.isArray(res))
        return
      let arrayOne = []
      let arrayTwo = []
      arrayOne = res.filter(value => value.working_area_user_type_id == 1)
      arrayTwo = res.filter(value => value.working_area_user_type_id == 2)
      this.working_areas = [{ title: "Benim çalışma alanlarım", data: arrayOne }, { title: "İşbirliği yaptığım çalışma alanları", data: arrayTwo }]
    });
  }

  setSidebarButtonClick() {
    this.sidebar_search_button_click = !this.sidebar_search_button_click
  }

  setSelectedWorkingAreas({ working_area_id }) {
    post('/api/panelUser/get_by_working_area', {
      working_area_id
    }).then((res) => {
      this.selected_working_areas = {
        working_area_id,
        panels: res.panels,
        working_area_user_type_id: res.working_area_user_type_id,
        lead_user_id: res.working_area_user_id,
        working_area: res.working_area
      }
    })
  }

  setIO(data) {
    this.io = data
  }
}

export const Store = new InstantStoreC();
