import { getUser } from "networking/Server";
import { io, Socket } from "socket.io-client";




const config = {
    jsonp: false,
    reconnection: true,
    reconnectionDelay: 100,
    reconnectionAttempts: 100000,
    transports: ['websocket']
}

// noinspection DuplicatedCode
class IOService {
    io;

    constructor(url) {

        this.io = io(url, { ...config, query: { token: localStorage.getItem("token") } });

        this.listeners()

    }

    disconnect() {
        this.io.disconnect()

    }


    connect_control() {
        return this.io.connected
    }

    emit(name, data) {
        let control = false;

        if (this.connect_control()) {
            this.io.emit(name, data);
            control = true
        }

        return control;
    }


    listeners() {

        this.io.on('connect', () => {

            this.emit('login', { user_id: getUser()['user_id'] });
        });
        this.io.on('reconnect', () => {
            alert("reconnect")
            this.emit('login', {});

        });
        this.io.on('disconnected', () => {
            alert("disconnected")
        });


    }

    c_listener(key, fun) {
        this.io.on(key, fun);

    }





}

export default IOService;
