import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "@history.js";
import { post } from "networking/Server";
import axios from '../../../util/Api'
import { Store } from '../../../networking/stores/Store'
import { error } from "functions/toast";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword(values) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });
    post("login", values, false)
      .then((res) => {
        if (res.result == false) {
          error(res.message)

        }
        else {
          localStorage.setItem("token", res.token);
          // MStore.setToken(res.token)
          axios.defaults.headers.common['x-access-token'] = "Bearer " + res.token;
          dispatch(getUser(res.token));
          dispatch(setUserData(res.user));
          // if (res.user.user_type_id === 4)
          //   Store.setPanels()
          history.push({
            pathname: "/dashboard"
          });
          return dispatch({
            type: LOGIN_SUCCESS
          });
        }
      }).catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export const getUser = (token) => {
  return (dispatch) => {

    if (!token) {
      const token = localStorage.getItem('token');
    }
    axios.defaults.headers.common['x-access-token'] = 'Bearer ' + token;

    //    dispatch({ type: FETCH_START });
    axios.post('/api/auth/me',
    ).then(({ data }) => {
      if (data.result) {

        //MStore.setUser(data.user)
        Store.setUser(data.user)
        if (data.user.user_type_id === 4)
          Store.setPanels()
        dispatch({ type: LOGIN_SUCCESS });
        //dispatch({ type: USER_DATA, payload: data.user });

      } else {

        localStorage.removeItem("token");
        // dispatch({ type: FETCH_ERROR, payload: data.error });
        //dispatch({ type: UPDATE_LOAD_USER, payload: false });

      }
    }).catch(function (error) {
      // dispatch({ type: FETCH_SUCCESS });
      //dispatch({ type: UPDATE_LOAD_USER, payload: false });
    });
  }
};


export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}

export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(user => {
        if (user) {
          dispatch(
            setUserData({
              userId: "1",
              role: "ADMIN",
              displayName: "Watson Joyce",
              email: "watsonjoyce@gmail.com",
              photoURL: "/assets/images/face-7.jpg",
              age: 25,
              token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
              ...user
            })
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}
