import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard1 = lazy(() => import("../ui-kits/Dashboard/Dashboard"));

const Dashboard2 = lazy(() => import("../ui-kits/Dashboard/Dashboard"));

const dashboardRoutes = [
  {
    path: "/dashboard/",
    component: Dashboard1,
    auth: authRoles.admin
  },
  {
    path: "/dashboard/v2",
    component: Dashboard2,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;
