import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";
import trTR from 'antd/lib/locale/tr_TR';
import { ConfigProvider } from 'antd';

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import CustomModal from "components/CustomModal/CustomModal";

function App() {
  return (

    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <ConfigProvider locale={trTR}>

          <Auth>
            <CustomModal />
            <Suspense fallback={<Loading></Loading>}>
              <Router history={history}>{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </Auth>
        </ConfigProvider>

      </Provider>
    </AppContext.Provider>
  );
}

export default App;
